<template>
  <div>
    <h2>老年人能力评估表 - B.3 精神状态评估表</h2>

    <div v-for="(question, index) in questions" :key="index" class="question-section">
      <h3>{{ question.title }}</h3>
      <div v-for="(option, idx) in question.options" :key="idx" class="option">
        <label>
          <input
            type="radio"
            :name="'question' + index"
            :value="option.score"
            v-model="question.selectedScore"
          >
          {{ option.text }}
        </label>
      </div>
    </div>

    <div class="total-score">
      <h3>总计得分: {{ totalScore }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          title: "B.3.1 时间定向：知道并确认时间的能力",
          selectedScore: 4,
          options: [
            { text: "时间观念（年、月）清楚，日期（或星期几）可相差一天", score: 4 },
            { text: "时间观念有些下降，年、月、日（或星期几）不能全部分清（相差两天或以上）", score: 3 },
            { text: "时间观念较差，年、月、日不清楚，可知上半年或下半年或季节", score: 2 },
            { text: "时间观念很差，年、月、日不清楚，可知上午、下午或白天、夜间", score: 1 },
            { text: "无时间观念", score: 0 },
          ],
        },
        {
          title: "B.3.2 空间定向：知道并确认空间的能力",
          selectedScore: 4,
          options: [
            { text: "能在日常生活范围内单独外出，如在日常居住小区内独自外出购物等", score: 4 },
            { text: "不能单独外出，但能准确知道自己日常生活所在地的地址信息", score: 3 },
            { text: "不能单独外出，但知道较多有关自己日常生活的地址信息", score: 2 },
            { text: "不能单独外出，但知道较少自己居住或生活所在地的地址信息", score: 1 },
            { text: "不能单独外出，无空间观念", score: 0 },
          ],
        },
        {
          title: "B.3.3 人物定向：知道并确认人物的能力",
          selectedScore: 4,
          options: [
            { text: "认识长期共同一起生活的人，能称呼并知道关系", score: 4 },
            { text: "能认识大部分共同生活居住的人，能称呼或知道关系", score: 3 },
            { text: "能认识部分日常同住的亲人或照护者等，能称呼或知道关系等", score: 2 },
            { text: "只认识自己或极少数日常同住的亲人或照护者等", score: 1 },
            { text: "不认识任何人（包括自己）", score: 0 },
          ],
        },
        {
          title: "B.3.4 记忆：短时、近期和远期记忆能力",
          selectedScore: 4,
          options: [
            { text: "总是能保持与社会、年龄所适应的记忆能力，能完整的回忆", score: 4 },
            { text: "出现轻度的记忆紊乱或回忆不能（不能回忆即时信息，3个词语经过5分钟后仅能回忆0-1个）", score: 3 },
            { text: "出现中度的记忆紊乱会回忆不能（不能回忆近期记忆，不记得上一顿饭吃了什么）", score: 2 },
            { text: "出现重度的记忆紊乱或回忆不能（不能回忆远期记忆，不记得自己的老朋友）", score: 1 },
            { text: "记忆完全紊乱或者完全不能对既往事务进行正确的回忆", score: 0 },
          ],
        },
        {
          title: "B.3.5 理解能力：理解语言信息和非语言信息的能力（可借助平时使用助听设备等），即理解别人的话",
          selectedScore: 4,
          options: [
            { text: "能正常理解他人的话", score: 4 },
            { text: "能理解他人的话，但需要增加时间", score: 3 },
            { text: "理解有困难，需频繁重复或简化口头表达", score: 2 },
            { text: "理解有严重困难，需要大量他人帮助", score: 1 },
            { text: "完全不能理解他人的话", score: 0 },
          ],
        },
        {
          title: "B.3.6 表达能力：表达信息能力，包括口头的和非口头的，即表达自己的想法",
          selectedScore: 4,
          options: [
            { text: "能正常表达自己的想法", score: 4 },
            { text: "能表达自己的需要，但需要增加时间", score: 3 },
            { text: "表达需要有困难，需频繁的重复或简化口头表述", score: 2 },
            { text: "表达有严重困难，需要大量他人帮助", score: 1 },
            { text: "完全不能理解他人的话", score: 0 },
          ],
        },
        {
          title: "B.3.7 攻击行为：身体攻击行为（如打/踢/推/咬/抓/摔东西）和语言攻击行为（如骂人、语言威胁、尖叫） 注：长期的行为状态",
          selectedScore: 1,
          options: [
            { text: "未出现", score: 1 },
            { text: "近一个月内出现过攻击行为", score: 0 },
          ],
        },
        {
          title: "B.3.8 抑郁症状：存在情绪低落、兴趣减退、活力减退等症状，甚至出现妄想、幻觉、自杀念头或自杀行为 注：长期的负性情绪",
          selectedScore: 1,
          options: [
            { text: "未出现", score: 1 },
            { text: "近一个月内出现过负性情绪", score: 0 },
          ],
        },
        {
          title: "B.3.9 意识水平：机体对自身和周围环境的刺激做出应答反应的能力程度，包括清醒和持续的觉醒状态 注：处于昏迷状态者，直接评定为重度失能",
          selectedScore: 2,
          options: [
            { text: "神志清醒，对周围环境能做出正确反应", score: 2 },
            { text: "嗜睡，表现为睡眠状态过度延长。当呼唤或推动老年人的肢体时可唤醒，并能进行正确的交谈或执行指令，停止刺激后又继续入睡；意识模糊，注意力涣散，对外界刺激不能清晰的认识，空间和时间定向力障碍，理解力迟钝，记忆力模糊和不连贯", score: 1 },
            { text: "昏睡，一般的外界刺激不能使其觉醒，给予较强烈的刺激时可有短时的意识清醒，醒后可简短回答提问，当刺激减弱后又很快进入睡眠状态；或者昏迷；意识丧失，随意运动丧失，对一般刺激全无反应。", score: 0 },
          ],
        },
      ],
    };
  },
  computed: {
      mentalStateScore: {
      get() { return this.$store.state.home_older.mentalStateScore },
      set(val) { this.$store.commit('home_older/updateMentalStateScore', val) }
    },
    totalScore() {
      this.mentalStateScore = this.questions.map(question => question.selectedScore);
      return this.questions.reduce((sum, question) => sum + question.selectedScore, 0);
    },
  },
};
</script>

<style scoped>
.question-section {
  margin-bottom: 20px;
}

.option {
  margin-left: 20px;
}

.total-score {
  margin-top: 30px;
  font-weight: bold;
}
</style>
